import React from "react";
import { Provider } from "react-redux";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./containers/Home";
import Products from "./containers/Products";
import ProductDetail from "./containers/ProductDetail";
import Gps from "./containers/Gps";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Footer from "./containers/Footer";
import TermsOfService from "./containers/TermsOfService";
import AOS from "aos";
import 'aos/dist/aos.css';
import './app.css'





class App extends React.Component {
    componentDidMount() {
        AOS.init({
            duration: 3000,

        })
    }
    render() {
        return (
            <Provider store={this.props.store}>
                <Router>
                    <React.Fragment>
                        <div className="body" style={{ minHeight: 700 }}>
                            <Route
                                exact={true}
                                path={AppRoutes.home}
                                component={Home}
                            />

                            <Route exact path={AppRoutes.products} component={Products} />
                            <Route exact path={AppRoutes.product} component={ProductDetail} />
                            <Route path={AppRoutes.gps} component={Gps} />
                            <Route path={AppRoutes.policy} component={PrivacyPolicy} />
                            <Route path={AppRoutes.terms} component={TermsOfService} />


                        </div>


                        <Footer />
                    </React.Fragment>
                </Router>
            </Provider>

        )
    }
}

export default App;
