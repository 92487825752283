const BASE_PATH = '';
const AppRoutes = {
    home: `${BASE_PATH}/`,
    login: `https://www.app.osrit.com/login`,
    signup: `${BASE_PATH}/signup`,
    register: `${BASE_PATH}/register`,
    contact: `${BASE_PATH}/contact`,
    products: `${BASE_PATH}/products`,
    product: `${BASE_PATH}/products/:id`,
    gps: `${BASE_PATH}/gps`,
    terms: `${BASE_PATH}/terms`,
    termsDownload: `${BASE_PATH}/terms-download`,
    policy: `${BASE_PATH}/policy`,
    blog: `blog.osrit.com`,

    contactUs: `${BASE_PATH}/contact-us`,

    demo: "https://calendly.com/osrit/30min"
}
export default AppRoutes;
