import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import AppRoutes from "../../AppRoutes";
import "./footer.scss";

class Footer extends React.Component {
    render() {
        const today = new Date();
        return (
            <>
                <div className="body">
                    <Row id="homeFooter" style={{ background: 'black', fontSize: 16, fontWeight: 600 }}>
                        <Col className="footer">
                            <li>  <Link style={{ color: 'white' }} to={AppRoutes.home} className="">
                                {"Home"}
                            </Link>{" "}</li>
                            <li>    <Link style={{ color: 'white' }} to={AppRoutes.products} className="">
                                {"Products"}
                            </Link></li>
                            <li>    <Link style={{ color: 'white' }} to={AppRoutes.gps} className="">
                                {"GPS"}
                            </Link></li>
                        </Col>
                        <Col style={{ marginTop: 40, listStyle: 'none', color: 'white' }} >
                            <li> <a style={{ color: 'white' }} href="http://osrit.com/" >Osrit</a></li>
                            <li> <a style={{ color: 'white' }} href="https://blog.osrit.com/">Trucking Blog</a></li>
                            <li> <a style={{ color: 'white' }} href="http://truckersoftwares.com/" >Trucker Software</a></li>
                        </Col>
                        <Col style={{ marginTop: 40, listStyle: 'none', }}>
                            <li> <a style={{ color: 'white' }} href="https://eldbooks.com/">ELD books</a></li>
                            <li> <a style={{ color: 'white' }} href="https://www.asritsolutions.com/" >Asrit Solutions</a></li>
                            <li>    <a style={{ color: 'white' }} href="https://www.osrit.com/pricing" className="">
                                {"Pricing"}
                            </a></li>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: 'center', background: 'black', }}>
                        <Col className="footer_right">
                            <Link style={{ color: 'white' }} to={AppRoutes.policy} className="">
                                {"Privacy Policy"}
                            </Link>{" "}
                            <Link style={{ color: 'white' }} to={AppRoutes.terms} className="">
                                {"Terms of Service"}
                            </Link>
                        </Col>
                    </Row>
                    <p style={{ textAlign: 'center', background: 'black', color: 'white', paddingBottom: 20 }}>
                        © {today.getFullYear()},{" "}
                        <a style={{ color: 'white', }}
                            href="http://www.asritsolutions.com"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Asritsolutions LLC.
             </a>{" "}
                        All Rights Reserved.
         </p>
                </div>
            </>
        );
    }
}

export default Footer;
