import React from "react";
import { Row, Col } from "reactstrap";
import "./banner.scss";

const Banner = () => (
    <Row id="homeBanner" style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 0, paddingRight: 0 }}>
        <Col sm={12} md="5" lg="4" style={{ padding: 10 }} className="text-center">
            <h3 className="h3 text-center">Request a Formal Quote!</h3>
        </Col>
        <Col sm={12} md="" lg="4" />
        <Col sm={12} md="5" lg="4" style={{ padding: 10 }} className="text-center">
            <a href="https://www.osrit.com/pricing"
                rel="noopener noreferrer" className="home_banner_btn center-vertical" >
                {"QUOTE REQUEST FORM"}
            </a>
        </Col>
    </Row>
);

export default Banner;
