import React from "react";
import _get from 'lodash/get';
import { Row, Col, Button } from "reactstrap";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import "./products.scss";
import { products } from "../../data/data";
import Banner2 from "../Banner2";

import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from "reactstrap";

class Products extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    constructor(props) {
        super(props)
        const id = _get(this, "props.match.params.id");
        const product = products[id] || {}
        const images = product.images || []
        this.state = {
            activeIndex: 0,
            animating: false,
            length: images.length || 0,
            isBenefit: true
        }
    }

    next = () => {
        const { animating, activeIndex, length } = this.state
        if (animating) return;
        const nextIndex = activeIndex === length - 1 ? 0 : activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        const { animating, activeIndex, length } = this.state
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? length - 1 : activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    goToIndex = newIndex => {
        const { animating } = this.state
        if (animating) return;
        this.setState({ activeIndex: newIndex });
    };

    getTitle = () => {
        const { isBenefit, isFeature, isBox, isOptional } = this.state
        if (isBenefit) { return "Benefits" }
        if (isFeature) { return "Features" }
        if (isBox) { return "Box" }
        if (isOptional) { return "Optional Accessories" }
    }
    render() {
        const id = _get(this, "props.match.params.id");
        const product = products[id] || {}
        const { activeIndex, isBenefit, isFeature, isBox, isOptional } = this.state
        return (

            <Container
                id="dispatchWrapper"
                style={{ padding: 0 }}
                fluid={true}
            >
                <Banner />
                <Header curr={"dispatch"} />

                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    interval={3000}
                >
                    {/* <CarouselIndicators
                        items={product.images || []}
                        activeIndex={activeIndex}
                        onClickHandler={this.goToIndex}
                    /> */}
                    {product.images.map((item, i) =>
                        <CarouselItem
                            onExiting={() => this.setState({ animating: true })}
                            onExited={() => this.setState({ animating: false })}
                            key={`${i}`}
                        >
                            <div style={{ maxHeight: 1000 }}>
                                <img
                                    src={item}
                                    style={{ width: "100%", maxHeight: "100%", marginBottom: 40 }}
                                    alt={"osrit"}
                                />

                                <div
                                    style={{
                                        // backgroundColor: "rgba(0, 0, 0, 0.45)",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",

                                    }}
                                />
                            </div>

                            {/* <CarouselCaption
                                captionText={item.altText}
                                captionHeader={item.caption}
                            /> */}
                        </CarouselItem>)
                    }
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                    />
                </Carousel>
                <hr style={{ marginBottom: 40, marginTop: 40 }} />
                <Row style={{ padding: 40, marginTop: 0 }}>
                    <Col sm="12" md="6" className="text-center">
                        <h1 className="h1">MORE INFO</h1>
                        <Button data-aos="fade-right"
                            className={"more-info-btn " + (isBenefit ? "more-info-btn-color-selected" : "more-info-btn-color")}
                            onClick={() => {
                                this.setState({
                                    isBenefit: true,
                                    isFeature: false,
                                    isBox: false,
                                    isOptional: false
                                })
                            }}>

                            BENEFITS
							</Button>
                        <Button data-aos="fade-left"
                            className={"more-info-btn " + (isFeature ? "more-info-btn-color-selected" : "more-info-btn-color")}
                            onClick={() => {
                                this.setState({
                                    isBenefit: false,
                                    isFeature: true,
                                    isBox: false,
                                    isOptional: false
                                })
                            }}>
                            FEATURE
							</Button>
                        <Button data-aos="fade-right"
                            className={"more-info-btn " + (isBox ? "more-info-btn-color-selected" : "more-info-btn-color")}
                            onClick={() => {
                                this.setState({
                                    isBenefit: false,
                                    isFeature: false,
                                    isBox: true,
                                    isOptional: false
                                })
                            }}>
                            WHAT'S IN THE BOX.?
							</Button>
                        <Button data-aos="fade-left"
                            className={"more-info-btn " + (isOptional ? "more-info-btn-color-selected" : "more-info-btn-color")}
                            onClick={() => {
                                this.setState({
                                    isBenefit: false,
                                    isFeature: false,
                                    isBox: false,
                                    isOptional: !isOptional
                                })
                            }}>
                            OPTIONAL ACCESSORIES
							</Button>
                    </Col>

                    <Col sm="12" md="6">
                        <div style={{ marginTop: 40 }}>
                            <h3 className="h3"> {this.getTitle()}</h3>
                            {isBenefit ?
                                <div data-aos="fade-up-left" >{product.benefits}</div> : null}
                            {isFeature ? <ul>
                                {product.features.map((item, i) =>
                                    <li data-aos="fade-up-left" data-aos-delay={200 * i}>{item}</li>
                                )}
                            </ul> : null}
                            {isBox ? <ul>
                                {product.box.map((item, index) =>
                                    <li data-aos="fade-up-left" data-aos-delay={400 * index}>{item}</li>
                                )}
                            </ul> : null}
                            {isOptional ? <ul>
                                {product.optional.map((item, index) =>
                                    <li data-aos="fade-up-left" data-aos-delay={400 * index}>{item}</li>
                                )}
                            </ul> : null}
                        </div>
                    </Col>

                </Row>
                <Banner2 />
            </Container>
        );
    }
}

export default Products;
