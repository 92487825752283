import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
const items = [
    {
        title: "Video Evidence Captured",
        alt: "",
        icon: "",
        detail:
            "High-Resolution cameras capture images of the road and (optionally) inside, rear, and sides of the vehicle."
    },
    {
        title: "GPS Location Recorded",
        alt: "",
        icon: "",
        detail:
            "Built-in GPS/Glonass provides precise event location, driving route, vehicle speed & direction."
    },
    {
        title: "Network Connected",
        alt: "",
        icon: "",
        detail:
            "Cellular connectivity provides real-time images, live location, and on-demand video access."
    },
    {
        title: "Events & Driver analysed",
        alt: "",
        icon: "",
        detail: "Detailed telemetry data enables accurate scoring for driver coaching purposes and reducing claims."
    },
];


const RowFourContent = props => (
    <Col md="3" style={{ padding: '10px' }}>
        <Card data-aos="fade-right" data-aos-delay={400 * 2} className="round-card" style={{ height: '100%', alignItems: 'center', padding: "36px 16px" }}>
            <h5 style={{ textAlign: "center" }}>{props.content.title}</h5>
            <CardBody className="home_row_four_content_body"
                style={{ textAlign: 'center', padding: 12 }}
            >
                {props.content.detail}
            </CardBody>
        </Card>

    </Col>
);
class RowFour extends React.Component {
    render() {
        return (
            <React.Fragment >
                <Row style={{ padding: 16, margin: 0 }}>
                    {items.map((item, i) => (
                        <RowFourContent key={i} index={i + 1} content={item} />
                    ))}
                </Row>
            </React.Fragment>
        );
    }
}

export default RowFour;
