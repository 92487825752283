import React from "react";
import { Row, Col } from "reactstrap";
import AppRoutes from "../../AppRoutes";
import { Link } from "react-router-dom";
import "./banner.scss";

const Banner = () => (
    <Row id="homeBanner">
        <Col xs={6} style={{ padding: 0 }}>
            <div className="home_banner_sales">
                <a href="tel:+1 (408) 520-0014">
                    Sales: +1 (408) 520 - 0014
                </a>
            </div>
        </Col>
        <Col xm={6} className="text-right" style={{ padding: 0 }}>
            {/* <button
                className="home_banner_btn demo"
                onClick={e => {
                    e.preventDefault();
                    window.Calendly.initPopupWidget({
                        url: "https://calendly.com/osrit/30min"
                    });
                }}
            >
                QUOTE REQUEST FORM
            </button> */}
            <Link to={AppRoutes.signup} className="home_banner_btn">
                {"REGISTER"}
            </Link>
        </Col>
    </Row>
);

export default Banner;
