import React from "react";
import { Row, Col } from "reactstrap";
const itemTitle = {
    fontSize: 20,
    fontWeight: 600

};

const itemDetail = {
    fontSize: 14,
    marginTop: 15,
    fontWeight: 300,

};

const allItem = [
    {
        title: "Real-Time GPS Tracking",
        detail:
            "Track in real time. Everything is accessed over the web and mobile APP.Each real-time GPS tracking vehicle device comes with NetTrack, Track Your Truck's easy-to-use monitoring and reporting software.",
        image: "realtime",
        alt: "Realtime vehicle gps tracker"
    },
    {
        title: "Daily Route Maps",
        detail: "View all the journeys and routes taken on a day.Daily route maps shows some routes and places which I must travel everyday.",
        image: "route",
        alt: "daily google routes map osrit trucking software"
    },
    {
        title: "Live Alert Notifications",
        detail: "Be alerted to just the events you consider important. Live Alerts enables advertisers to be proactive in their marketing efforts. By setting alerts, you can monitor a wide range of attribution and Protect360 KPIs.",
        image: "alerts",
        alt: "trucking Live Alert Notifications"
    },
    {
        title: "Create Geo-fences",
        detail: "Complete control and protect your assets and business.Building your own geofence solution seems easy enough: find the point of interest you want to geofence, find the latitude as well as longitude point it corresponds to, identify the precise boundaries in the real world the fence corresponds to, make sure the location sensors in your app are tuned just right to keep connected..",
        image: "geofence",
        alt: "free geofencing software for trucks"
    },
    {
        title: "Driver Behavior",
        detail:
            "See speeding, harsh acceleration & braking, sharp turn, unnecessary idling, etc.Human error is therefore the primary cause of such tragedies. Driver behavior modeling (DBM) has primarily emerged to predict driving maneuvers, driver intent, vehicle and driver state, and environmental factors, to improve transportation safety and the driving experience as a whole.",
        image: "driverBehaviour",
        alt: "truck drivers behaviour and attitude"
    },
    {
        title: "Reports & Analytics",
        detail:
            "Data-rich reports generate analytics to help save you time and money.Reporting is “the process of organizing data into informational summaries in order to monitor how different areas of a business are performing.” ... Analytics is “the process of exploring data and reports in order to extract meaningful insights, which can be used to better understand and improve business performance.”",
        image: "reports",
        alt: "Trucks Driver Reports & Analytics"
    }
];
const RenderItem = ({ data }) => {
    return (
        <div className="content-card">
            <div >
                <img
                    alt={data.alt}
                    style={{
                        maxHeight: 100,
                        minHeight: 100
                    }}
                    src={require(`../../images/gps/${data.image}.png`)}
                />
            </div>
            <div>
                <div style={itemTitle} >{data.title}</div>
                <div style={itemDetail}>{data.detail}</div>
            </div>
        </div >
    );
};

class PricingContent extends React.Component {
    onSignUp = () => { };
    render() {
        return (
            <Row style={{ marginLeft: 10, marginRight: 10, }}>
                {allItem.map((item, i) => {
                    return (
                        <Col data-aos="zoom-out" data-aos-delay={100 * i} key={i} md={6} lg={4} xs={12} style={{ padding: 20, }}>
                            <RenderItem data={item} />
                        </Col>
                    );
                })}
            </Row>
        );
    }
}

export default PricingContent;
